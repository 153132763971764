import React from "react";
import { Item } from "react-photoswipe-gallery";

export const Piece = (props) => {
  return (
    <Item
      original={props.piece.Image.asset.url}
      height={props.piece.Image.asset.metadata.dimensions.height}
      width={props.piece.Image.asset.metadata.dimensions.width}
    >
      {({ ref, open }) => (
        <div className="px-2 pb-4">
          <div className="">
            <img ref={ref} onClick={open} src={props.piece.Image.asset.url} />
            {typeof props.piece.title === "object"
              ? "untitled"
              : props.piece.title}
          </div>
        </div>
      )}
    </Item>
  );
};
