function Product(props) {
  return (
    <div className="product-item px-4 py-2 ">
      <a href={props.link} target="_blank" rel="noopener noreferrer">
        <img src={props.src} alt={props.name} />
        <p>{props.name}</p>
        <p>{"$" + props.price}</p>
      </a>
    </div>
  );
}

export default Product;
