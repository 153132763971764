import React from "react";
import { Piece } from "./PortfolioPiece";
import "photoswipe/dist/photoswipe.css";

import { Gallery } from "react-photoswipe-gallery";

export const PortfolioPieces = () => {
  const [pieces, setPieces] = React.useState(null);

  React.useEffect(() => {
    if (!pieces) {
      fetch(
        "https://4h77y0za.api.sanity.io/v2021-10-21/data/query/production?query=*%5B_type%20%3D%3D%20%22imagePortfolio%22%20%5D%20%7B%0A%20%20Image%20%7B%0A%20%20asset-%3E%0A%7D%2C%0A%20%20title%2C%0A%20%20%0A%7D"
      )
        .then((response) => response.json())
        .then((data) => {
          setPieces(data.result);
        });
    }
  }, []);

  return (
    <>
      {pieces !== null && (
        <div className="grid px-2  grid-cols-2 sm:grid-cols-3 lg:grid-cols-4">
          <Gallery>
            {pieces.map((piece, index) => {
              return <Piece piece={piece} />;
            })}
          </Gallery>
          <br />
        </div>
      )}
    </>
  );
};
