import React from "react";

import "./App.css";
import Marq from "./components/Marq";
import { PortfolioPieces } from "./components/PortfolioPieces";
import vapor from "./loop.mp3";

function App() {
  const [audioPlaying, setAudio] = React.useState(false);

  return (
    <div>
      <div className="justify-between pt-2 px-5 flex flex-row">
        <h1>emilio smith</h1>
        <a href="mailto:emiliosmyith@gmail.com">contact</a>
      </div>
      <Marq />
      <div className="container mx-auto">
        <PortfolioPieces />
      </div>
      <audio loop id="audio_player">
        <source id="src_mp3" type="audio/mp3" src={vapor} />
      </audio>
      <div
        className="fixed select-none cursor-pointer	 bottom-5 left-5 text-white"
        onClick={() => {
          if (!audioPlaying) {
            document.getElementById("audio_player").play();
            setAudio(true);
          } else {
            document.getElementById("audio_player").pause();
            setAudio(false);
          }
        }}
      >
        {audioPlaying ? (
          <div className="text-7xl">■</div>
        ) : (
          <div className="text-5xl">▶</div>
        )}
      </div>
      <div className="w-screen text-center pb-5">site by richard bozo web productions</div>
    </div>
  );
}

export default App;
