import React from "react";

import Marquee from "react-fast-marquee";
import Product from "./Product";
function Marq() {
  const [products, setProducts] = React.useState(null);

  React.useEffect(() => {
    if (!products) {
      fetch(
        "https://4h77y0za.api.sanity.io/v2021-10-21/data/query/production?query=*%5B_type%3D%3D%22product%22%5D%7B%0A%20%20...%2C%0A%20%20Price%2C%0A%20%20Url%2C%0A%20%20title%2C%0A%20%20Image%20%7B%0A%20%20asset-%3E%0A%7D%0A%7D"
      )
        .then((response) => response.json())
        .then((data) => {
          setProducts(data.result);
        });
    }
  }, []);
  return (
    <div className="marq text-white pt-3 pb-3 my-2 mb-5">
      <p className="text-center text-2xl text-white pb-2">prints for sale</p>

      {products !== null && (
        <>
          <div className="md:hidden">
            {products.map((data, index) => {
              return (
                <div className="product-item px-4 py-2 ">
                  <div>
                    <a
                      href={data.Url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={data.Image.asset.url} alt={data.title} />
                      <p>{data.title}</p>
                      <p>{"$" + data.Price}</p>
                    </a>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="hidden md:block">
            <Marquee
              className="text-left "
              gradient={false}
              pauseOnHover={true}
            >
              {products.map((data, index) => {
                return (
                  <Product
                    src={data.Image.asset.url}
                    name={data.title}
                    price={data.Price}
                    link={data.Url}
                  />
                );
              })}
              {products.map((data, index) => {
                return (
                  <Product
                    src={data.Image.asset.url}
                    name={data.title}
                    price={data.Price}
                    link={data.Url}
                  />
                );
              })}
            </Marquee>
          </div>
        </>
      )}
    </div>
  );
}

export default Marq;
